@use '@/scss/variables' as *;
@use '@/scss/tools' as *;

.container {
  width: 100%;
  position: relative;
  background: $background;
  display: flex;
  @include fullVHeight();

  @include media(tablet) {
    position: relative;
  }

  @include media(desktop) {
    display: flex;
  }
}

.header {
  position: relative;
  width: 100%;
  min-height: 4rem;
  @include media(tablet) {
    min-height: 7rem;
  }

  &.leftAligned {
    @include flex(space-between, center);
    margin-bottom: 2rem;
  }

  &:not(.leftAligned) {
    @include flex(center, center);
    margin-bottom: 4rem;
  }

  [role='heading'] {
    @include heading7-typeface();
    display: inline-block;
    margin: auto;
    @include media(tablet) {
      display: none;
    }
  }

  [role='zendesk-chat-button'] {
    z-index: 10;
    @include media(desktop) {
      position: fixed;
      bottom: 4rem;
      right: 5.2rem;
    }
  }
}

.content {
  height: 100%;
  width: 100%;
  @include flex(flex-start, center, column, nowrap);
  padding: 1.6rem 1.6rem 2.4rem;

  @include media(tablet) {
    padding: 2.6rem 6.4rem 4rem;
  }

  @include media(desktop) {
    padding: 2.6rem 9.2rem 4rem;
    width: 60vw;
  }
}

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media(tablet) {
    margin: 0 auto;
    max-width: 64rem;
  }

  @include media(desktop) {
    max-width: 52rem;
  }
}

.aside {
  position: relative;
  display: none;

  @include media(desktop) {
    position: fixed;
    right: 0;
    display: block;
    width: 40vw;
    height: 100%;
    img {
      object-fit: cover;
    }
  }
}
